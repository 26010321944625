import React, { useReducer, useEffect } from "react";
import ReactDOM from "react-dom";
import useScript from "react-script-hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";

let PayPalButton = () => (
  <FontAwesomeIcon icon={faSpinnerThird} spin size="3x" />
);

const PayPalCheckout = (props) => {
  const { createOrder, onApprove } = props;
  const [loading, error] = useScript({
    src: `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
    checkForExisting: true,
  });

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (loading === false && error === null) {
      PayPalButton = window.paypal.Buttons.driver("react", {
        React,
        ReactDOM,
      });
      forceUpdate();
    }
  }, [loading, error]);

  return (
    <div className="w-full text-center">
      {error === null ? (
        <PayPalButton createOrder={createOrder} onApprove={onApprove} />
      ) : (
        <div
          className="flex items-center bg-orange-500 text-white text-sm font-bold px-4 py-3 mb-3"
          role="alert"
        >
          <p>
            <FontAwesomeIcon icon={faExclamationTriangle} /> PayPal payment
            options cannot to loaded.
          </p>
        </div>
      )}
    </div>
  );
};

PayPalCheckout.defaultProps = {
  createOrder: (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: "0.01",
          },
        },
      ],
    });
  },
  onApprove: (data, actions) => {
    return actions.order.capture();
  },
};

export default PayPalCheckout;

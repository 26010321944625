import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationSquare } from "@fortawesome/pro-duotone-svg-icons";

const NotFound = (props) => {
  useEffect(() => {
    document.title = "404 Not Found";
  }, []);
  return (
    <div className="h-screen flex items-center justify-center">
      <p className="sm:text-xl md:text-3xl lg:text-5xl xl:text-6xl text-gray-500 font-bold">
        <FontAwesomeIcon icon={faExclamationSquare} /> 404 Not Found
      </p>
    </div>
  );
};

export default NotFound;

import React, { useState, useEffect } from "react";
import classnames from "classnames";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import CheckoutForm from "components/CheckoutForm";
import PaymentFinish from "components/PaymentFinish";
import PayPalCheckout from "./PayPalCheckout";

const Checkout = (props) => {
  const {
    order,
    onPaymentIntent,
    onPaymentIntentRetrieve,
    onPayPalCreateOrder,
    onPayPalApprove,
  } = props;

  const { id, amount, pmts, country, currency } = order;

  const [error, setError] = useState();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  useEffect(() => {
    if (pmts.indexOf("prb") >= 0 && stripe) {
      const idDec = id.split("-");
      const pr = stripe.paymentRequest({
        country: "DE",
        currency,
        total: {
          label: "Invoice #" + idDec[idDec.length - 1],
          amount: amount * 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result) {
          pr.on("paymentmethod", async (event) => {
            const cs = await onPaymentIntent();
            if (cs !== false) {
              setError();
              const { error: confirmError } = await stripe.confirmCardPayment(
                cs,
                {
                  payment_method: event.paymentMethod.id,
                },
                { handleActions: false }
              );

              if (confirmError) {
                event.complete("fail");
              } else {
                event.complete("success");

                const {
                  error,
                  paymentIntent,
                } = await stripe.confirmCardPayment(cs);
                if (error) {
                  setError(error.message);
                } else {
                  if (paymentIntent.status === "succeeded") {
                    onPaymentIntentRetrieve(paymentIntent.id);
                    setFinish(true);
                  }
                }
              }
            }
          });
          setPaymentRequest(pr);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pmts, stripe]);

  const [finish, setFinish] = useState(false);
  const handleSubmit = async (data) => {
    if (!stripe || !elements) {
      return;
    }
    const { email, name, country } = data;
    const cs = await onPaymentIntent();
    if (cs !== false) {
      setError();
      const { error, paymentIntent } = await stripe.confirmCardPayment(cs, {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name,
            email,
            address: {
              country,
            },
          },
        },
      });
      if (error) {
        setError(error.message);
      } else {
        if (paymentIntent.status === "succeeded") {
          onPaymentIntentRetrieve(paymentIntent.id);
          setFinish(true);
        }
      }
    }
  };

  return (
    <>
      {finish !== true ? (
        <div className="bg-white border box-border shadow rounded m-12">
          <div
            className={classnames({
              "divide-y": pmts.length > 1,
              "divide-gray-300": pmts.length > 1,
            })}
          >
            {pmts.indexOf("card") >= 0 && (
              <div>
                <h2 className="px-10 pt-6 text-2xl subpixel-antialiased">
                  Pay with card
                </h2>
                <div
                  className={classnames("px-10", "pt-6", "pb-8", {
                    "mb-4": pmts.length > 1,
                  })}
                >
                  <CheckoutForm
                    order={order}
                    error={error}
                    onSubmit={handleSubmit}
                  />
                </div>
              </div>
            )}

            {pmts.filter((type) => {
              if (
                type === "card" ||
                (type === "prb" && paymentRequest === null)
              ) {
                return false;
              }
              return true;
            }).length > 0 && (
              <div className="px-8 pt-6 pb-6">
                {pmts.indexOf("card") >= 0 && pmts.length > 1 && (
                  <p className="text-center text-sm antialiased font-light pb-2">
                    Or use other payment options
                  </p>
                )}
                {pmts.indexOf("prb") >= 0 && paymentRequest && (
                  <div
                    className="pb-4 my-0 mx-auto"
                    style={{ maxWidth: "750px" }}
                  >
                    <PaymentRequestButtonElement
                      options={{
                        paymentRequest,
                        style: { paymentRequestButton: { height: "55px" } },
                      }}
                    />
                  </div>
                )}
                {pmts.indexOf("paypal") >= 0 && country !== "TW" && (
                  <PayPalCheckout
                    createOrder={onPayPalCreateOrder}
                    onApprove={async (data, actions) => {
                      const status = await onPayPalApprove(data, actions);
                      setFinish(status === "COMPLETED");
                    }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="min-h-screen">
          <PaymentFinish />
        </div>
      )}
    </>
  );
};

export default Checkout;

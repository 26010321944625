import React from "react";
import { CardElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      iconColor: "#6772e5",
    },
  },
};

const CardSection = (props) => {
  const { onReady, onFocus, onBlur, onChange } = props;
  return (
    <CardElement
      options={CARD_ELEMENT_OPTIONS}
      onReady={onReady}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
};

CardSection.defaultProps = {
  onReady: () => {},
  onFocus: () => {},
  onBlur: () => {},
  onChange: () => {},
};

export default CardSection;

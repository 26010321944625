import React from "react";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCreditCard,
  faSpinnerThird,
} from "@fortawesome/pro-duotone-svg-icons";

const PayButton = (props) => {
  const { amount, loading, onClick } = props;

  const handleClick = (event) => {
    onClick(event.type);
  };

  return (
    <button
      type="submit"
      className={classnames(
        "block w-full bg-indigo-500 text-white font-bold py-2 px-4 rounded disabled:opacity-75",
        {
          "hover:bg-indigo-600": !loading,
        }
      )}
      onClick={handleClick}
      disabled={loading}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinnerThird} spin />
      ) : (
        <>
          <FontAwesomeIcon icon={faCreditCard} /> {`Pay $${amount}`}
        </>
      )}
    </button>
  );
};

PayButton.defaultProps = {
  amount: "1.00",
  loading: false,
  onClick: () => {},
};

export default PayButton;

import React, { useState, useEffect } from "react";
import Checkout from "pages/Checkout/Checkout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const api =
  process.env.NODE_ENV === "production"
    ? "/api"
    : process.env.REACT_APP_API_BASE_URL;

function Index(props) {
  const {
    history,
    match: {
      params: { id },
    },
  } = props;

  useEffect(() => {
    document.title = "Checkout";
  }, []);

  const [order, setOrder] = useState();
  useEffect(() => {
    axios
      .get(`${api}/checkout/v2/order/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const result = response.data;
          if (result.status === 0) {
            setOrder(result.data);
            return;
          }
        }
        history.push("/404");
      })
      .catch(() => {
        history.push("/404");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePaymentIntent = async () => {
    try {
      const response = await axios.get(
        `${api}/checkout/v2/payment/intent/${id}`
      );
      if (response.status === 200 || response.status === 201) {
        const result = response.data;
        if (result.status === 0) {
          return result.data.client_secret;
        }
      }
    } catch (error) {}
    return false;
  };

  const handlePaymentIntentRetrieve = (pi_id) => {
    axios.post(`${api}/checkout/v2/payment/intent/${id}`, {
      pi_id,
    });
  };

  const handlePayPalCreateOrder = async () => {
    try {
      const response = await axios.get(
        `${api}/checkout/v2/payment/paypal/${id}`
      );
      if (response.status === 200 || response.status === 201) {
        const result = response.data;
        if (result.status === 0) {
          return result.data.id;
        }
      }
    } catch (error) {}
  };

  const handlePayPalApprove = async (data, actions) => {
    const result = await actions.order.capture();
    if (result.status === "COMPLETED") {
      axios.post(`${api}/checkout/v2/paypal/capture`, {
        order_id: result.id,
      });
    }
    return result.status;
  };

  return (
    <div>
      {order && (
        <Elements stripe={stripePromise}>
          <Checkout
            order={order}
            onPaymentIntent={handlePaymentIntent}
            onPaymentIntentRetrieve={handlePaymentIntentRetrieve}
            onPayPalCreateOrder={handlePayPalCreateOrder}
            onPayPalApprove={handlePayPalApprove}
          />
        </Elements>
      )}
    </div>
  );
}

export default Index;

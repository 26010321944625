import React, { useState } from "react";
import classnames from "classnames";
import { countries } from "countries-list";
import { useForm } from "react-hook-form";
import sortBy from "lodash/sortBy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationSquare } from "@fortawesome/pro-solid-svg-icons";
import CardSection from "../CardSection";
import PayButton from "../PayButton";
import "./index.css";

const CheckoutForm = (props) => {
  const { order, onSubmit, error } = props;
  const { register, handleSubmit, errors, formState } = useForm();
  const { isSubmitting } = formState;
  const onWrapSubmit = async (data) => {
    await onSubmit(data);
  };

  const [focusCardSection, setFocusCardSection] = useState(false);
  const [cardError, setCardError] = useState(null);
  const [stripeElement, setStripeElement] = useState();
  const handleCardSectionFocus = () => {
    setFocusCardSection(true);
  };
  const handleCardSectionBlur = () => {
    setFocusCardSection(false);
  };
  const handleCardSectionReady = (StripeElement) => {
    setStripeElement(StripeElement);
  };
  const handleCardSectionChange = (event) => {
    if (event.error) {
      setCardError(event.error.message);
    } else {
      setCardError(null);
    }
  };

  return (
    <div className="w-full">
      {error && (
        <div
          className="flex items-center bg-red-500 text-white text-sm font-bold px-4 py-3 mb-3"
          role="alert"
        >
          <p>
            <FontAwesomeIcon icon={faExclamationSquare} /> {error}
          </p>
        </div>
      )}
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          if (cardError) {
            if (stripeElement) {
              stripeElement.focus();
              return;
            }
          }
          await handleSubmit(onWrapSubmit)(event);
        }}
      >
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-gray-700 text-sm mb-1">Email</label>
            {errors.email && (
              <div>
                <span className="text-xs font-bold text-red-500">
                  {errors.email.message}
                </span>
              </div>
            )}
          </div>
          <input
            name="email"
            type="email"
            className={classnames(
              "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm",
              {
                "border-red-500": errors.email,
              }
            )}
            ref={register({
              required: "REQUIRED",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "INVALID EMAIL ADDRESS",
              },
            })}
          />
        </div>
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-gray-700 text-sm mb-1">
              Name on card
            </label>
            {errors.name && (
              <div>
                <span className="text-xs font-bold text-red-500">
                  {errors.name.message}
                </span>
              </div>
            )}
          </div>

          <input
            name="name"
            className={classnames(
              "shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-sm",
              {
                "border-red-500": errors.name,
              }
            )}
            ref={register({
              required: "REQUIRED",
            })}
          />
        </div>
        <div className="mb-6">
          <div className="flex justify-between">
            <label className="block text-gray-700 text-sm mb-1">
              Country or region
            </label>
            {errors.country && (
              <div>
                <span className="text-xs font-bold text-red-500">
                  {errors.country.message}
                </span>
              </div>
            )}
          </div>
          <div className="inline-block relative w-full">
            <select
              name="country"
              defaultValue={order.country || "US"}
              className="block appearance-none w-full b-white border border px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              ref={register({
                required: "REQUIRED",
              })}
            >
              {sortBy(Object.entries(countries), (country) => {
                const [, value] = country;
                return value.name;
              }).map((country) => {
                const [key, value] = country;
                return (
                  <option key={key} value={key}>
                    {value.name}
                  </option>
                );
              })}
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg
                className="fill-current h-4 w-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div className="mb-8">
          <div className="flex justify-between">
            <label className="block text-gray-700 text-sm mb-1">
              Card information
            </label>
            {cardError && (
              <div>
                <span className="text-xs font-bold text-red-500">
                  {cardError}
                </span>
              </div>
            )}
          </div>
          <div
            className={classnames(
              "shadow",
              "appearance-none",
              "border",
              "rounded",
              "w-full",
              "py-2",
              "px-3",
              "text-gray-700",
              "leading-tight",
              {
                "outline-none": focusCardSection,
                "shadow-outline": focusCardSection,
                "border-red-500": cardError !== null,
              }
            )}
          >
            <CardSection
              onReady={handleCardSectionReady}
              onFocus={handleCardSectionFocus}
              onBlur={handleCardSectionBlur}
              onChange={handleCardSectionChange}
            />
          </div>
        </div>
        <div>
          <PayButton amount={order.amount} loading={isSubmitting} />
        </div>
      </form>
    </div>
  );
};

CheckoutForm.defaultProps = {
  order: {},
  onSubmit: async () => {},
};

export default CheckoutForm;

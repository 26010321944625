import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";

const PaymentFinish = (props) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <p className="text-green-500">
        <FontAwesomeIcon icon={faCheckCircle} size="5x" />
      </p>
      <p className="m-3 font-medium text-lg text-gray-700">
        Thank you, your payment has been received. Your service will be updated
        shortly.
      </p>
    </div>
  );
};

export default PaymentFinish;
